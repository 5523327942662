const formatNumberToDigits = (number: number): string => {
    // Convert the number to a string
    let strNumber = number.toString();

    // Pad the string with leading zeros to reach the desired length of 6 digits
    while (strNumber.length < 6) {
        strNumber = '0' + strNumber;
    }

    return strNumber;
};

export default formatNumberToDigits;