import deTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/de_DE.json";
import dePeople from "../modules/CMS/people/localization/locales/de_DE.json";
import deCustomer from "../modules/CMS/customer/localization/locales/de_DE.json";
import deBilling from "../modules/CMS/billing/localization/locales/de_DE.json";
import deOffer from "../modules/CMS/offer/localization/locales/de_DE.json";


import enTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/en_US.json";
import enPeople from "../modules/CMS/people/localization/locales/en_US.json";
import enCustomer from "../modules/CMS/customer/localization/locales/en_US.json";
import enBilling from "../modules/CMS/billing/localization/locales/en_US.json";
import enOffer from "../modules/CMS/offer/localization/locales/en_US.json";


export const RegisterLanguageDE = {
    ...deTicketsystemMessages,
    ...dePeople,
    ...deCustomer,
    ...deBilling,
    ...deOffer
}

export const RegisterLanguageEN = {
    ...enTicketsystemMessages,
    ...enPeople,
    ...enCustomer,
    ...enBilling,
    ...enOffer
}

const deLang = {
    messages: {
        ...RegisterLanguageDE
    },
    locale: 'de',
};
const EnLang = {
    messages: {
        ...RegisterLanguageEN
    },
    locale: 'us',
};

export const AppLocale: any = {
    "de": deLang,
    "us": EnLang
};

